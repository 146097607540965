import React from 'react'
import _times from 'lodash/times'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { TitleSection } from '../../common/Index.styled'
import Section from '../../common/Section'
import { ContractButtonSmall } from '../SolutionDetail.styled'

const PageContentContable = () => {
  return (
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.contable.title" />
      </TitleSection>
      <div>
        <p>
          <FormattedMessage
            id="solutions.contable.content.p1"
            values={{ companyName: <b>AS Consultores</b> }}
          />
        </p>
        <h3>
          <FormattedMessage id="solutions.contable.content.subtitle1" />
        </h3>
        <ul>
          {_times(4, String).map((value, key) => (
            <li key={key}>
              <FormattedHTMLMessage
                id={`solutions.contable.content.list1.item${value}`}
              />
            </li>
          ))}
        </ul>

        <p>
          <FormattedHTMLMessage id="solutions.contable.content.p2" />
        </p>
        <h3>
          <FormattedMessage id="solutions.contable.content.subtitle2" />
        </h3>
        <ul>
          {_times(4, String).map((value, key) => (
            <li key={key}>
              <FormattedHTMLMessage
                id={`solutions.contable.content.list2.item${value}`}
              />
            </li>
          ))}
        </ul>
        <p>
          <FormattedHTMLMessage id="solutions.contable.content.p3" />
        </p>
      </div>
      <ContractButtonSmall to="/contacto/" small>
        <FormattedMessage id="element.button_hire" />
      </ContractButtonSmall>
    </Section>
  )
}

export default PageContentContable
