import React from 'react'
import Layout from '../components/Layout'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/contable.png'
import FooterContact from './../components/FooterContact'
import PageContentContable from '../components/Solutions/Pages/Contable'

const Contable = props => (
  <Layout
    title="Servicio Contable"
    description="Con nuestra Solución Contable le garantizamos que usted dejará de trabajar para su contador; a través de nuestros avanzados sistemas tecnológicos le ofrecemos una solución pertinente, rápida y efectiva. Únicamente requerimos sus Estados de Cuenta bancarios semanalmente, y nosotros nos encargamos del resto."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <PageContentContable />
    <FooterContact />
  </Layout>
)

export default Contable
